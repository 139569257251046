import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../state/store'
import { selectUser } from '../state/userSlice'

const LoginPage = () => {
  const user = useAppSelector(selectUser)
  const isLoggedIn = user.id > 0
  const navigate = useNavigate()

  useEffect(() => {
    if (isLoggedIn) navigate(-1)
  }, [isLoggedIn, navigate])

  return (
    <div className="mx-auto w-full flex-col flex-1 flex bg-background items-center">
      <h1 className="my-4 text-2xl text-center">로그인</h1>
      <div className="mb-5 w-96">
        {/* <a
          className="block py-2 text-center bg-[#41CE60]  rounded-md mt-3 w-full text-white"
          href={`${process.env.REACT_APP_API_URL}/auth/naver/agency`}>
          naver로 계속하기
        </a> */}
        <a
          className="block py-2 text-center bg-[#F8E814] rounded-md mt-3 w-full text-black"
          href={`${process.env.REACT_APP_API_URL}/auth/kakao/agency`}>
          kakao로 계속하기
        </a>
        <a
          className="block py-2 text-center bg-[#000000] rounded-md mt-3 w-full text-white"
          href={`${process.env.REACT_APP_API_URL}/auth/apple/agency`}>
          apple로 계속하기
        </a>
        <a
          className="block py-2 text-center bg-[#4484F4] rounded-md mt-3 w-full text-white"
          href={`${process.env.REACT_APP_API_URL}/auth/google/agency`}>
          google로 계속하기
        </a>
      </div>
    </div>
  )
}

export default LoginPage
